import React from 'react'
import Layout from '../components/layout/layout'
import ProjectTeaser from '../components/projectTeaser/projectTeaser'
import {graphql} from 'gatsby'

import HeadlinePortableText from '../components/headlinePortableText/headlinePortableText'

const ProjectIndex = ({data}) => {
  if (data.allSanityProjectArchive.nodes.length < 1) return null

  const {
    _rawDescription,
    projects,
    title
  } = data.allSanityProjectArchive.nodes[0]
  
  return (
    <Layout title={title} className='template-project-achive'>
      <div className='container-fluid'>
        <HeadlinePortableText content={_rawDescription} />
        {
          projects.map(project => (
            <ProjectTeaser project={project} key={project._id} />
          ))
        }
      </div>
    </Layout>
  )
}

export default ProjectIndex

export const query = graphql`
  query ProjectsPageQuery {
    allSanityProjectArchive {
      nodes {
        _rawDescription
        title
        projects {
          _id
          title
          facts {
            projectCategory {
              title
            }
            squareMeter
            finishedAt
            location
          }
          fileDownload {
            asset {
              url
              originalFilename
            }
          }
          images {
            mainImg {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              hotspot {
                height
                x
                width
                y
              }
            }
            additionalImg {
              asset {
                fluid(maxWidth: 3000) {
                  ...GatsbySanityImageFluid_noBase64
                }
              }
              hotspot {
                height
                x
                width
                y
              }
            }
          }
        }
      }
    }
  }
`
